import baseColors from '@elegantstack/flow-ui-theme/src/theme/colors'

//Using tailwind preset colors
import colorPreset from '@elegantstack/flow-ui-theme/src/theme/color-preset'

const colors = {
    ...baseColors,
    alphaLighter: colorPreset.blue[100],
    alphaLight: colorPreset.blue[300],
    alpha: colorPreset.blue[600],
    alphaDark: colorPreset.blue[800],
    alphaDarker: colorPreset.blue[900]
}

export default colors
